const { location } = window;

document.getElementById('submit').addEventListener('click', e => {
  e.preventDefault();
  const url =
    'https://discordapp.com/api/webhooks/725864235850137662/rVjRxPGm9-TvNF3kaaXilugu3eDbEQYD9qqamJvM8Xyd02at4rE47PNQFsJZBiU-Q-YQ';
  const content = document.getElementById('content').value;
  const email = document.getElementById('email').value;
  const name = document.getElementById('name').value;
  fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: `Contact Form: ${email}, ${name}`,
      embeds: [
        {
          color: 11730954,
          description: content
        }
      ]
    })
  });
  location.reload();
});
